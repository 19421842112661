import { configureStore } from '@reduxjs/toolkit';
import windowObserver from '@utils/WindowObserver/window-observer.slice';
import cookies from '@utils/Cookies/cookies.slice';

const createStoreArgs = [];

if (import.meta.env.DEV) {
  const { createLogger } = await import('redux-logger');
  const logger = createLogger({
    collapsed: (_getState, _action, logEntry) => !logEntry.error,
  });

  createStoreArgs.push(logger);
}

const store = configureStore({ 
  reducer: {
    windowObserver,
    cookies,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(createStoreArgs),
});

export default store

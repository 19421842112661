import T1 from '@templates/T1/T1';
import Navbar from './Navbar/Navbar';
import InteractiveMap from './InteractiveMap/InteractiveMap';
// import Datapanel from './Datapanel/Datapanel';
// import Footer from './Footer/Footer';

function BasePage() {
  return (
    <T1
      Navbar={<Navbar />}
      InteractiveMap={<InteractiveMap />}
      // Datapanel={</>}
      // Footer={<Footer />}
    />
  )
}

export default BasePage;
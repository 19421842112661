import React from 'react'

import './navbar.styl'

function Navbar() {
  return (
    <div className="navbar z-depth-1">
      <img src="/img/logo/tardigrade.png" alt="main logo" />
    </div>
  )
}

export default Navbar
import { v4 as uuidv4 } from 'uuid';

function generateUniqueId() {
  return uuidv4();
}

function removeExternalLoader() {
  const loaderEl = document.querySelector('.js-external-loader');

  if (!loaderEl) {
    return;
  }

  loaderEl.parentNode.removeChild(loaderEl);
}

function getSizeFromResizeObserverEntry(entry) {
  if (!entry.contentBoxSize) {
    return {
      width: entry.contentRect.width,
      height: entry.contentRect.height,
    };
  }

  const { 
    inlineSize: width, 
    blockSize: height, 
  } = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;

  return { width, height };
}

const tagBody = '(?:[^"\'>]|"[^"]*"|\'[^\']*\')*';

const tagOrComment = new RegExp(
  `${'<(?:'
  + '!--(?:(?:-*[^->])*--+|-?)' // Comment body.
  + '|script\\b'}${tagBody}(/|>[\\s\\S]*?</script\\s*)` // <script...>...</script> & <script.../>
  + `|style\\b${tagBody}(/|>[\\s\\S]*?</style\\s*)` // <style...>...<style> & <style.../>
  + `|iframe\\b${tagBody}(/|>[\\s\\S]*?</iframe\\s*)` // <iframe...>...<iframe> & <iframe.../>
  + ')>',
  'gi',
);

const sanitizeHTML = html => html.replace(tagOrComment, '');

async function loadScript(url, attributes = {}) {
  await new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = url;

    Object.keys(attributes).forEach((key) => {
      script[key] = attributes[key];
    });

    script.addEventListener('load', () => resolve());
    script.addEventListener('error', () => reject());

    document.body.appendChild(script);
  });
}

export {
  generateUniqueId,
  removeExternalLoader,
  getSizeFromResizeObserverEntry,
  sanitizeHTML,
  loadScript,
}
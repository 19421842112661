import { MEDIA_QUERIES } from './window-observer.constants';

function initMediaQueries(cb) {
  MEDIA_QUERIES.forEach(({ mediaQuery }) => {
    cb(mediaQuery);

    mediaQuery.addEventListener('change', cb);
  })
}

function destroyMediaQueries(cb) {
  MEDIA_QUERIES.forEach(({ mediaQuery }) => mediaQuery.removeEventListener('change', cb));
}

export {
  initMediaQueries,
  destroyMediaQueries,
}
import { screen } from '@css/theme.style.json';

/*  Exports
======================================================== */
const MEDIA_QUERIES = Object.entries(screen).map(([key, value]) => {
  const mediaQuery = window.matchMedia(
    `${(value['min-width'] ? `(min-width: ${value['min-width']})` : '')}`
    + `${(value['min-width'] && value['max-width'] ? ' and ' : '')}`
    + `${(value['max-width'] ? `(max-width: ${value['max-width']})` : '')}`,
  );

  const minWidth = value['min-width'] ? parseInt(value['min-width'], 10) : 0;
  const maxWidth = value['max-width'] ? parseInt(value['max-width'], 10) : Infinity;
  const name = key.toUpperCase();

  return {
    name, minWidth, maxWidth, mediaQuery,
  };
});

export {
  MEDIA_QUERIES,
}
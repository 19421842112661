import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { MEDIA_QUERIES } from './window-observer.constants';
import { initMediaQueries, destroyMediaQueries } from './window-observer.utils';
import { updateBreakpoint } from './window-observer.slice';

function WindowObserver() {
  const dispatch = useDispatch();
  
  useEffect(() => {
    function handleMediaQueryChange(e) {
      const {
        minWidth, maxWidth, name, 
      } = MEDIA_QUERIES.find(({ mediaQuery }) => (mediaQuery.media === e.media));
    
      if (e.matches) {
        dispatch(updateBreakpoint({
          name,
          minWidth,
          maxWidth,
        }))
      }
    }

    initMediaQueries(handleMediaQueryChange);

    return () => destroyMediaQueries(handleMediaQueryChange);
  }, [dispatch]);

  return null;
}

export default WindowObserver;

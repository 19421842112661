import { useEffect, useRef, useState } from 'react';

import { usePrev } from '@services/hooks.service';
import Controls from './controls/Controls/Controls';
import { mapFactory, addSourcesAndLayersFromConfigToMap } from './maptiler-map.factory';
import { resizeMap } from './maptiler-map.utils';
import {me} from './damage.geojson'

import '@maptiler/sdk/dist/maptiler-sdk.css';
import './maptiler-map.styl';

function MaptilerMap(props) {
  const [map, setMap] = useState(null)
  const { mapResizeId, onMapInitialized } = props
  const mapRef = useRef(null);
  const prevMapResizeId = usePrev(mapResizeId)
  const prevMap = usePrev(map)
  
  useEffect(() => {
    setMap(mapFactory(mapRef.current));
  }, []);

  useEffect(() => {
    if (map && (map !== prevMap)) {
      map.on('load', () => addSourcesAndLayersFromConfigToMap(map));
      onMapInitialized();
    }
  }, [map, onMapInitialized, prevMap]);

  useEffect(() => {
    if (map && (map !== prevMap)) {
      map.on('load', async () => {
        map.addSource('damage', {
          type: 'geojson',
          data: me,
        });
        map.addLayer({
          id: 'damage',
          type: 'fill-extrusion',
          source: 'damage',
          paint: {
            'fill-extrusion-color': [
              'match',
              ['get', 'Risk'],
              "H",
              "#ce0000",
              "M",
              "#ffae00",
              "L",
              "#008000",
              "W",
              "#0000FF",
              "#CCCCCC"
            ],
            'fill-extrusion-height': 4,
            'fill-extrusion-opacity': [
                    "interpolate",
                    [
                        "linear"
                    ],
                    [
                        "zoom"
                    ],
                    11,
                    0.6,
                    15,
                    0.3,
                    18,
                    0.2
                ],
            // 'fill-color': '#98b',
            // 'fill-opacity': 0.8,
          },
          filter: [
                "==",
                [
                    "geometry-type"
                ],
                "Polygon"
            ]
        }, 'z-index-1');
        map.addLayer({
          id: 'border',
          type: 'line',
          source: 'damage',
           layout: {
                "visibility": "visible",
                "line-cap": "round",
                "line-join": "round"
            },
            paint: {
                "line-opacity": 0.8,
                "line-color": "hsla(0, 0%, 100%, 0.5)",
                "line-blur": 0,
                "line-gap-width": 0
            },
            filter: [
                "==",
                [
                    "geometry-type"
                ],
                "Polygon"
            ]
        });
      });
    }
}, [map])

  useEffect(() => {
    if ((mapResizeId !== prevMapResizeId) && map) {
      resizeMap(map);
    }
  }, [map, mapResizeId, prevMapResizeId]);

  return (
    <div ref={mapRef} className="maptiler-map">
      <Controls map={map} />
    </div>
  );
}

export default MaptilerMap;
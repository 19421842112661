import BasePage from './pages/BasePage/BasePage';

const routes = [
  {
    name: 'Page Not Found', 
    path: '*', 
    element: <BasePage />,
  },
];

export default routes;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  breakpoint: null,
}

const windowObserver = createSlice({
  name: 'windowObserver',
  initialState,
  reducers: {
    updateBreakpoint: (state, action) => {
      state.breakpoint = action.payload;
    },
  },
})

export const { updateBreakpoint } = windowObserver.actions

export default windowObserver.reducer
import * as maptilersdk from '@maptiler/sdk';

import style from './style/style.base.json';
import sources from './style/style.sources.json';
import layers from './style/style.layers.json';

maptilersdk.config.apiKey = import.meta.env.VITE_MAPTILER_TOKEN;

/*  Exports
    ======================================================================================= */
function addSourcesAndLayersFromConfigToMap(mapboxMap) {
  Object.keys(sources).forEach(sourceId => mapboxMap.addSource(sourceId, sources[sourceId]));
  layers.forEach(layer => mapboxMap.addLayer(layer));

  // this are empty layers make sure the order of layers are always as intended
  for (let i = 0; i < 10; i += 1) {
    mapboxMap.addLayer({
      id: `z-index-${i}`,
      type: 'fill',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      },
      paint: {},
    });
  }

  ['River', 'Water', 'River labels', 'Bridge'].forEach((id) => {
    mapboxMap.moveLayer(id, 'z-index-2');
  });

  return mapboxMap;
}

function mapFactory(mapEl) {
  const options = { 
    container: mapEl,
    style: '0958dd3c-eb74-437c-b551-ae1bc92c9db7',
    center: [-1.564921, 47.207888],
    geolocateControl: false,
    navigationControl: false,
    attributionControl: false,
    pitch: 85,
    zoom: 16,
  };

  return new maptilersdk.Map(options);
}

export {
  mapFactory,
  addSourcesAndLayersFromConfigToMap,
}
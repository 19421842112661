import React, { useState } from 'react'
import MaptilerMap from '@library/MaptilerMap/MaptilerMap'

import './interactive-map.styl'; 

function InteractiveMap() {
  const [isMapLoading, setIsMapLoading] = useState(true)
  const [mapResizeId, setMapResizeId] = useState()
  
  return (
    <div className="interactive-map">
      <MaptilerMap
        mapResizeId={mapResizeId}
        onMapInitialized={() => setIsMapLoading(false)}
      />
    </div>
  )
}

export default InteractiveMap